.navigator .highlight {
  opacity: 0.4;
  filter: alpha(opacity=40);
  outline: 2px solid #900;
  background-color: #900;
}

.highlight {
  opacity: 0.4;
  filter: alpha(opacity=40);
  outline: 12px auto #0a7ebe;
  background-color: white;
}

.highlight:hover,
.highlight:focus {
  background-color: #0a7ebe;
}
